import React, { useState, useEffect, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { appTheme } from 'common/theme/app';
import {
  GlobalStyle,
  AppWrapper,
  ConditionWrapper,
} from 'containers/App/app.style';
import { ResetCSS } from 'common/assets/css/style';
import Navbar from 'containers/App/Navbar';
import DomainSection from 'containers/App/Banner';
import FeatureSection from 'containers/App/FeatureSection';
import ControllSection from 'containers/App/Control';
import TestimonialSection from 'containers/App/Testimonial';
import PartnerHistory from 'containers/App/PartnerHistory';
import PaymentSection from 'containers/App/PaymentSection';
import LoginModal from 'containers/App/LoginModal';
import Footer from 'containers/App/Footer';
import FeatureSlider from 'containers/App/FeatureSlider';
import FeatureSliderTwo from 'containers/App/FeatureSliderTwo';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import CircularProgressOverlay from 'common/mycomponents/CircularProgressOverlay';
import '@redq/reuse-modal/es/index.css';
import SEO from 'components/seo';
import useBreakpoint from 'hooks/useBreakpoint';

const DriverPage = () => {
  const breakpoint = useBreakpoint().breakpoint;
  const isMobile = ['xl'].includes(breakpoint);
  const [loginOpen, setLoginOpen] = useState(false);

  const openLoginModal = () => {
    setLoginOpen(true);
  }

  return (
    <ThemeProvider theme={appTheme}>
      <Fragment>
        <SEO title="PerGo Rides - Free dispatch and payment for transportation companies" />
        <ResetCSS />
        <GlobalStyle />
        <CircularProgressOverlay>
          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar openLogin={openLoginModal} />
              </DrawerProvider>
            </Sticky>
            <DomainSection openLogin={openLoginModal} />
            <FeatureSection />
            <ControllSection openLogin={openLoginModal} />
            <ConditionWrapper id="keyfeature">
              {isMobile ? <FeatureSlider /> : <FeatureSliderTwo />}
            </ConditionWrapper>
            <PartnerHistory />
            <PaymentSection />
            <TestimonialSection />
            <Footer />

            <LoginModal open={loginOpen} handleClose={() => setLoginOpen(false)} />
          </AppWrapper>
        </CircularProgressOverlay>
      </Fragment>
    </ThemeProvider>
  );
};

export default DriverPage;