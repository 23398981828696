import React from 'react';
import PropTypes from 'prop-types';
import 'react-image-gallery/styles/css/image-gallery.css';
import Container from 'common/components/UI/Container';
import DriverSliderWrapper from './driverSlider.style';
import ImageSlider from '../FeatureSlider/ImageSlider';

const DriverSlider = () => {
  return (
    <DriverSliderWrapper>
      <div className="DriverSliderInner">
        <span> </span>
        <span> </span>
        <span> </span>
      </div>
      <Container className="DriverSlider">
        <ImageSlider />
      </Container>
    </DriverSliderWrapper>
  );
};

export default DriverSlider;
